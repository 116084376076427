import React from "react"

import Container from "@ecom/ui/components/Container"
import Img from "./Img"

import * as styles from "./aboutHalva.module.scss"

type AboutHalvaProps = {
  title?: string
  description?: string
  orderNum?: string
}

const defaultTitle = `
  Карта «Халва» – это:
`

const defaultDescription = `
— 24 месяца рассрочки на покупки и&nbsp;рефинансирование кредиток
<br />
— до 17% на остаток собственных средств для новых клиентов
<br />
— до 10% кэшбэк
`

export function AboutHalva({
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
}: AboutHalvaProps) {
  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.secondContainer}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.text}>
            <h2 className={styles.head} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={styles.subText} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </Container>
    </section>
  )
}
